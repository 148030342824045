<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Faça login em sua conta</p>
                  <CInput
                    placeholder="Usuário" 
                    autocomplete="username"
                    v-model="user"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Senha"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >                  
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="danger" class="px-4" @click="logar">Entrar</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Esqueceu a senha?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="danger"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Olá!</h2>
                <p>Bem vindo ao SRI-Bank Admin. Caso ainda não possua credenciais de acesso, entre em contato com o seu gerente. </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      title="Aviso!"
      color="danger"
      :show.sync="showAlert"
    >
      {{ msg }}
    </CModal>
  </div>
</template>

<script>
import functions from "@/functions";
const {vhost} = functions;
const axios = require("axios").default;

export default {
  name: 'Login',
  data(){
        return {
            user:"",
            password:"",
            showAlert: false,
            msg: "",
        }
    },
    methods:{
        logar: function() {
            if (!this.user || !this.password){
                return
            }
            axios({
                method: 'post',
                url: vhost('admin/login'),
                data: {
                    login: this.user,
                    password: this.password
                }
            })
            .then((res) => {
                if (res.data.auth===true && res.data.token && res.data.data){
                      localStorage.setItem('user-token', res.data.token);
                      localStorage.setItem('user-data',  JSON.stringify(res.data.data));
                      this.$router.push('/dashboard');
                      window.location.reload();
                } else if (res.data.erro){
                   this.setAlert(res.data.erro);
                } else {
                   const err='Não foi possível realizar o login. Erro desconhecido.'
                   this.setAlert(err);
                }
               
            })
            .catch(err=>{
                if (err.response.data && err.response.data.erro){
                  this.setAlert(err.response.data.erro);
                } else{
                  this.setAlert(err.message);
                }  
            });
            localStorage.setItem('user-token', '');
            localStorage.setItem('user-data', '');
        },
        setAlert: function (message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        }
    }
}
</script>
